body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/***************************************************

Megamenu

****************************************************/

.grid-menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
  column-gap: 10px;
  padding: 10px;
}

.grid-item-menu {
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 10px;
  font-size: 15px;
}
.submenu {
  font-size: 16px;
  font-weight: 700;
  color: #1B5E20 !important;
}
.submenu:hover {
  color: black; /*#1B5E20*/
}
.hr-submenu {
  width: 3.5rem;
  height: 0.2rem;
  background-color: black; /*#dadde6*/
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.submenu-content {
  padding-top: 15px;
  color: black !important;
}
.gap-4 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

/***************************************************

Blog

****************************************************/

.container-blog {
  background-color: white;
}

.grid-recents {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  padding-top: 0.5rem;
}

@media (min-width: 768px) {
  .grid-recents {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 768px) {
  .md-sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 10px;
  }
}

.rounded-full {
  border-radius: 9999px;
}

.custom-input-blog {
  height: 3rem;
  padding: 1rem 2rem;
  border: 1px solid rgba(220, 220, 220);
  color: black !important;
  font-size: 1.2rem;
  font-family: "Nunito Sans", sans-serif;
}

.custom-input-blog:focus {
  border-color: rgb(27, 94, 32);
  box-shadow: 0 0 0 0.2rem rgba(24, 94, 32, 0.25);
}

.text-related-posts {
  margin-top: 3rem;
  margin-bottom: 2rem;
  color: black;
  font-size: 1rem;
  font-family: "Nunito Sans", sans-serif;
}

.grid-related {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  width: 100%;
}
.tags-list {
  gap: 15px;
}

.tag-button {
  background-color: #cccccc59;
  border-radius: 7px;
  font-weight: 700;
  color: #1b5e20;
  font-size: 1rem;
  padding: 3px 18px;
  font-family: "Nunito Sans", sans-serif;
}
.tag-button-active {
  background-color: #1b5e20;
  color: #fff;
}

.tag-button:hover {
  background-color: #1b5e20;
  color: #fff;
}

.trending-posts .sidebar-item:hover .num-left {
  background: var(--color-primary);
}
.trending-posts .sidebar-item:hover .content-right a {
  color: var(--color-primary);
}

.trending-container {
  padding: 20px;
  border: 1px solid #e9ecef;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.trending-box-left {
  width: 20%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7b7b7b;
}

.trending-box-right {
  width: 80%;
  padding-left: 1.25rem;
  justify-content: flex-end;
}

.trending-box-right-text {
  font-size: 0.85rem;
  color: #000;
  font-weight: 600;
}

.trending-box-right-text:hover {
  color: rgba(0, 0, 0, 0.7);
}

.container-carroussel-blog {
  padding-top: 30px;
}
.swiper-carroussel-blog {
  width: 100% !important;
  height: 400px !important;
}

@media (min-width: 768px) {
  .swiper-carroussel-blog {
    height: 80px; /* Equivalent de md:h-80 */
  }
}

.swiper-carroussel-blog .swiper-pagination-bullet-active {
  background: var(--color-primary) !important;
}

.swiper-carroussel-blog .swiper-button-prev,
.swiper-carroussel-blog .swiper-button-next {
  left: unset !important;
  bottom: 10px !important;
  top: unset !important;
}

.swiper-carroussel-blog .swiper-button-prev {
  right: 200px !important;
}
.swiper-carroussel-blog .swiper-button-next {
  right: 100px !important;
}

.swiper-carroussel-blog .swiper-button-prev::after,
.swiper-carroussel-blog .swiper-button-next::after {
  font-size: 20px !important;
  background: rgba(0, 0, 0, 0.5);
  padding: 15px;
  color: white !important;
  font-weight: 700;
}

.title-blog {
  font-size: 1.1rem;
  color: black;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;
}

.title-category-blog {
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;
  color: #1b5e20;
  font-family: "Nunito Sans", sans-serif;
}
.desc-categ-blog {
  font-family: "Nunito Sans", sans-serif;
  color: #000;
  font-size: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1.5em * 2);
}

.tag-text-category {
  font-weight: bold;
  font-size: 1.2rem;
  text-decoration: none;
  color: #1b5e20;
  font-family: "Nunito Sans", sans-serif;
}
.blog-v-image {
  width: 100%;
  height: 170px !important;
}

.blog-h1-image {
  width: 100%;
  height: 80px !important;
}

.blog-category-title {
  font-weight: bold;
  font-size: 1.5rem;
  text-decoration: none;
  color: black;
  font-family: "Nunito Sans", sans-serif;
}
.category-container {
  border: 1px solid #ccc;
  padding: 15px;
}
.container-blog-detail {
  background: white;
  padding-right: 60px;
  padding-bottom: 80px;
}
@media (max-width: 768px) {
  .container-blog-detail {
    padding-right: 0px;
  }
}
.container-related-blog {
  padding-top: 3.5rem;
}

.container-see {
  padding-top: 30px;
  gap: 15px;
}
.see-button {
  border-radius: 7px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;
  color: #1b5e20;
}

.see-button:hover {
  background-color: #1b5e20;
  color: #fff;
}

.share-button {
  transition: opacity 0.8s ease, transform 0.4s ease;
}
.share-button:hover {
  filter: contrast(140%);
  transform: scale(1.1);
}

.no-blog {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: black;
}

.text-title-category {
  color: #1b5e20;
  font-size: 1rem;
  font-weight: 700;
}
.text-date {
  color: #ccc;
  font-size: 1rem;
  font-weight: 700;
}

.portable-text {
  text-align: justify;
  font-size: 18px;
  color: #000;
  font-family: "Nunito Sans", sans-serif;
}
.text-title-blog {
  font-family: "Nunito Sans", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: black;
}

.content-blog {
  padding-top: 7%;
}
.img-blog {
  max-height: 700px;
  object-fit: cover;
}

.gap-2 {
  gap: 0.5rem /* 8px */;
}

.gap-4 {
  gap: 1rem;
}

.items-center {
  align-items: center;
}
.inline-flex {
  display: inline-flex;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}

.text-base {
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
}
.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.underline {
  text-decoration-line: underline;
}
.underline-offset-2 {
  text-underline-offset: 2px;
}

.text-3xl {
  font-size: 1.875rem /* 30px */;
  line-height: 2.25rem /* 36px */;
}

.text-2xl {
  font-size: 1.5rem /* 24px */;
  line-height: 2rem /* 32px */;
}

.text-xl {
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
}

.text-lg {
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
}

.text-lg {
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
}
.m-auto {
  margin: auto;
}

.ml-4 {
  margin-left: 1rem /* 16px */;
}

.ml-12 {
  margin-left: 3rem /* 48px */;
}

/* Define the counter style */

@counter-style finger-pointer {
  system: cyclic;
  symbols: "\1F449";
  suffix: " ";
}

ul.my-list-style {
  list-style-type: finger-pointer;
}

/* .skeleton::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -150px;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  animation: loading 1.5s infinite;
} */

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 0.5rem 0;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -300px; /* Increase starting position for more effect */
  height: 100%;
  width: 300px; /* Increase width for more effect */
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.2)
  ); /* More contrast */
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    left: -300px; /* Match the starting position with the width */
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.skeleton-image {
  height: 200px;
  width: 100%;
}

.skeleton-text {
  height: 20px;
  width: 100%;
}

.skeleton-title {
  height: 30px;
  width: 60%;
}

.skeleton-date {
  height: 20px;
  width: 30%;
}
.contact-widget .icon {
  color: white !important;
}


.entrepriseDescription{  
  text-align: justify;
}
